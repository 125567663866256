import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Graph } from '@iot-platform/models/common';
import { Asset, FollowedVariable } from '@iot-platform/models/i4b';
import { WidgetAction, WidgetInstance, WidgetOptions, WidgetSize, WidgetTypes } from '@iot-platform/models/widgets';
import { GraphsService } from '@iot-platform/shared/services';
import { get } from 'lodash';
import { finalize } from 'rxjs/operators';
import { CardLoaderModule } from '../card-loader/card-loader.module';
import { VariableChartDialogComponent } from '../variable-chart/variable-chart-dialog/variable-chart-dialog.component';
// import { VariableChartDialogComponent } from '@iot-platform/feature/variable-chart';
import { WidgetsModule } from '../widgets/widgets.module';

@Component({
  imports: [FlexLayoutModule, MatCardModule, MatToolbarModule, MatIconModule, MatButtonModule, MatDialogModule, CardLoaderModule, WidgetsModule],
  selector: 'iot-platform-ui-graph-list-popup',
  templateUrl: './graph-list-popup.component.html',
  styleUrls: ['./graph-list-popup.component.scss']
})
export class GraphListPopupComponent implements OnInit {
  private readonly graphsService: GraphsService = inject(GraphsService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  public dialog: MatDialog = inject(MatDialog);
  public dialogRef: MatDialogRef<GraphListPopupComponent> = inject(MatDialogRef<GraphListPopupComponent>);
  public data: { asset: Asset; followedVariable: FollowedVariable; siteId: string } = inject(MAT_DIALOG_DATA);
  widgets: WritableSignal<WidgetInstance[]> = signal([]);
  loading: WritableSignal<boolean> = signal(true);

  private static buildWidget(graph: Graph): WidgetInstance {
    const options: WidgetOptions = {
      ...WidgetInstance.getOptionsByType(graph, {}),
      menu: { enabled: false },
      size: WidgetSize.small
    } as WidgetOptions;
    return new WidgetInstance({
      type: WidgetTypes[graph.type],
      data: graph,
      options
    });
  }

  ngOnInit(): void {
    this.initWidgets();
  }

  onDispatchEvent(graph: Graph, action: WidgetAction): void {
    if (action === WidgetAction.PREVIEW) {
      this.openGraph(graph);
    }
  }

  openGraph(graph: Graph): void {
    this.dialog.open(VariableChartDialogComponent, {
      width: '990px',
      data: {
        variables: graph.variables,
        variableType: 'assetVariable',
        title: graph.name
      }
    });
  }

  private initWidgets(): void {
    this.graphsService
      .getGraphsByAssetVariableId(get(this.data, 'followedVariable.id'), get(this.data, 'siteId'))
      .pipe(
        finalize(() => this.loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((graphs: Graph[]) => {
        const widgets = graphs.map((graph: Graph) => GraphListPopupComponent.buildWidget(graph));
        this.widgets.set([...widgets]);
      });
  }
}
