import { inject, Injectable, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { FeatureMasterViewEvent } from '@iot-platform/feature/master-view';
import { VariableChartDialogComponent } from '@iot-platform/feature/variable-chart';
import { GridsDbActions } from '@iot-platform/grid-engine';
import {
  CONFIGURE_GRIDS_BUTTON_CONFIG,
  EXPORT_BUTTON_CONFIG,
  FAVORITE_VIEWS_MENU_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  PopupComponent,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
  ToolbarPageType
} from '@iot-platform/iot-platform-ui';
import { BusinessProfile, ChatEvent, IotAction, IotToolbarEvent, MasterViewEngineEvent, Permission } from '@iot-platform/models/common';
import { CommandType, CommentContext, CommonBulkOperationType, Concept, Device, Log } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BulkManageTagDialogComponent } from '../../../../components/bulk-manage-tag-dialog/bulk-manage-tag-dialog.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { I4bMasterViewService } from '../../../../services/i4b-master-view.service';
import { DeviceDetailPopupComponent } from '../../components/device-detail-popup/device-detail-popup.component';
import { DeviceMoveToFormComponent } from '../../components/device-move-to-form/device-move-to-form.component';
import { DevicesFacade } from '../../state/facades/devices.facade';

@Injectable({
  providedIn: 'root'
})
export class DevicesShellMasterViewService extends I4bMasterViewService<Device> {
  commentsContexts!: CommentContext[];
  incomingConnectorUrl = '';
  currentBusinessProfile: Signal<BusinessProfile | null | undefined> = this.authFacade.selectedBusinessProfile;
  protected readonly translateService: TranslateService = inject(TranslateService);
  protected readonly storage: LocalStorageService = inject(LocalStorageService);
  protected readonly navigationApi: NavigationApi = inject(NavigationApi);
  protected readonly router: Router = inject(Router);

  constructor(protected readonly masterViewFacade: DevicesFacade) {
    super(masterViewFacade);
  }

  getConcept(): string {
    return 'DEVICE';
  }

  getMasterViewName(): string {
    return 'devices';
  }

  getGAEventName(): string {
    return 'devices_shell';
  }

  getPermissions(): Observable<Permission[]> {
    return this.authFacade.privileges$.pipe(
      filter((privileges) => !!privileges),
      map((_) => [
        { key: 'canReadDevice', value: this.masterViewFacade.canRead() },
        { key: 'canCreateDevice', value: this.masterViewFacade.canCreate() },
        { key: 'canUpdateDevice', value: this.masterViewFacade.canUpdate() }
      ])
    );
  }

  getToolbarButtons(): Observable<(IotToolbarDefaultButton | IotToolbarMenuButton)[]> {
    return of([
      new IotToolbarDefaultButton(
        {
          ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
          tooltip: 'IOT_TOOLBAR.TOOLTIP.TOGGLE_FILTER_ENGINE.DEVICES'
        },
        1
      ),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.REFRESH.DEVICES' }, 2),
      new IotToolbarDefaultButton({ ...EXPORT_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.EXPORT_DATA.DEVICES' }, 3),
      new IotToolbarMenuButton(
        {
          ...FAVORITE_VIEWS_MENU_BUTTON_CONFIG,
          tooltip: 'IOT_TOOLBAR.TOOLTIP.FAVORITE_VIEWS_MENU.DEVICES'
        },
        4
      ),
      new IotToolbarDefaultButton(
        {
          ...CONFIGURE_GRIDS_BUTTON_CONFIG,
          tooltip: 'IOT_TOOLBAR.TOOLTIP.CONFIGURE_GRIDS.DEVICES'
        },
        5
      )
    ]);
  }

  onToolbarEvent(_event: FeatureMasterViewEvent): void {
    super.onToolbarEvent(_event);
    const event: IotToolbarEvent = _event.event as IotToolbarEvent;

    switch (event.type) {
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.analytic.log('toolbar_actions', 'refresh_data');
        break;
      case IotToolbarDispatchActionType.TOGGLE_PAGE_TYPE:
        this.storage.set(LocalStorageKeys.STORAGE_DEVICES_PAGE_TYPE_KEY, event.options.pageType as ToolbarPageType);
        break;
      default:
        break;
    }
  }

  onMasterViewEngineEvent(_event: FeatureMasterViewEvent): void {
    super.onMasterViewEngineEvent(_event);
    const event: MasterViewEngineEvent = _event.event as MasterViewEngineEvent;
    const device = event.rawData as Device;

    switch (event.type) {
      case 'navigateToDevice':
        this.onSelectDevice(device);
        this.navigateToDevice(device);
        break;
      case 'navigateToSite':
        this.onSelectDevice(device);
        this.navigateToSite(device);
        break;
      case 'open':
        this.onSelectDevice(device);
        this.openDeviceDetailPopup(device);
        break;
      case 'moveTo':
        this.onSelectDevice(device);
        this.openMoveTo([device]);
        break;
      case 'bulkMoveTo':
        this.openMoveTo(event.rawData as Device[]);
        break;
      case CommandType.CONFIGURE:
        this.onSelectDevice(device);
        this.setIncomingConnectorUrl(device);
        this.onConfigureDeviceConnector(device);
        break;
      case 'openChartByVariable':
        this.onSelectDevice(device);
        this.openChartByVariable(device, event.options.variableName as string);
        break;
      case 'bulkAddTag':
        this.openBulkManageTagDialog(
          'add',
          event.rawData.map((d: Device) => d.id)
        );
        break;
      case 'bulkRemoveTag':
        this.openBulkManageTagDialog(
          'remove',
          event.rawData.map((d: Device) => d.id)
        );
        break;
      case 'bulkReplaceTag':
        this.openBulkManageTagDialog(
          'replace',
          event.rawData.map((d: Device) => d.id)
        );
        break;
      case CommandType.REFRESH:
        this.onSelectDevice(device);
        this.onSendRefreshCommand(device);
        break;
      case 'bulkRefresh':
        this.onBulkSendRefreshCommand(CommandType.REFRESH, event.rawData as Device[]);
        break;
      case 'openComments':
        this.onSelectDevice(device);
        this.onOpenComments(device);
        break;
      default:
        break;
    }
  }

  navigateToDevice(device: Device): void {
    this.navigationApi.openDeviceDetail(device);
  }

  navigateToSite(device: Device): void {
    this.navigationApi.openSiteDetailFromDevice(device);
  }

  openDeviceDetailPopup(device: Device) {
    this.analytic.log('grid_actions', 'open_device_detail');
    const dialog = this.dialog.open(DeviceDetailPopupComponent, {
      width: '900px',
      disableClose: false,
      data: { device }
    });

    dialog.componentInstance.selectDevice.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      dialog.close();
      this.navigateToDevice(device);
    });

    dialog.componentInstance.selectSite.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      dialog.close();
      this.navigateToSite(device);
    });
  }

  openMoveTo(devices: Device[]) {
    this.analytic.log('grid_actions', 'open_move_to');
    this.dialog
      .open(DeviceMoveToFormComponent, {
        width: '800px',
        disableClose: true,
        data: { devices }
      })
      .afterClosed()
      .subscribe((updatedDevices: Device[]) => {
        if (updatedDevices) {
          this.masterViewFacade.moveDevices(updatedDevices);
          this.analytic.log('grid_actions', 'move_to', `${updatedDevices.length} devices`);
        }
      });
  }

  openChartByVariable(device: Device, variableName: string) {
    this.toggleRefreshActivated(false);
    this.analytic.log('grid_actions', 'open_chart_by_variable');
    if (device?.expandedVariables[variableName]) {
      this.dialog
        .open(VariableChartDialogComponent, {
          width: '990px',
          data: {
            variables: [{ ...device?.expandedVariables[variableName], device: { id: device.id, name: device.name } }],
            variableType: 'deviceVariable'
          }
        })
        .afterClosed()
        .subscribe(() => this.toggleRefreshActivated(true));
    }
  }

  openBulkManageTagDialog(bulkOperation: CommonBulkOperationType, devicesIds: string[]): void {
    this.analytic.log('grid_actions', `open_bulk_manage_tag_${bulkOperation}`);
    this.dialog
      .open(BulkManageTagDialogComponent, {
        data: {
          bulkOperation,
          concept: 'device',
          entityId: this.currentBusinessProfile()?.entityId,
          selectionTotal: devicesIds.length
        },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((tagLabelId: string) => {
        if (tagLabelId) {
          this.masterViewFacade.bulkOperationOnTag(bulkOperation, devicesIds, tagLabelId);
          this.analytic.log('grid_actions', `bulk_${bulkOperation}_tag`);
        }
      });
  }

  onSendRefreshCommand(device: Device): void {
    this.analytic.log('grid_actions', 'send_refresh_command');
    this.masterViewFacade.sendCommand(device, { command: CommandType.REFRESH });
  }

  onBulkSendRefreshCommand(command: CommandType, devices: Device[]): void {
    const devicesIds = devices.filter((device) => device.isRefreshCommandEnabled).map((device) => device.id);
    this.analytic.log('cta_actions', 'open_bulk_send_command');
    this.dialog
      .open(PopupComponent, {
        data: {
          type: 'confirm',
          value: this.translateService.instant('DEVICES.SHELL.BULK_SEND_COMMAND.CONFIRMATION_MESSAGES.' + command.toUpperCase(), { total: devicesIds.length })
        },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation && devicesIds.length) {
          this.analytic.log('cta_actions', 'bulk_send_command', `${command} : ${devicesIds.length}`);
          this.masterViewFacade.bulkSendCommand(devicesIds as string[], { command: CommandType.REFRESH });
        }
      });
  }

  onConfigureDeviceConnector(device: Device) {
    this.analytic.log('grid_actions', 'configure_connector');

    if (device.outgoingConnector?.configuration?.sharedAuth) {
      window.open(this.incomingConnectorUrl);
    } else {
      window.open(device.incomingConnector?.configuration?.url);
    }
  }

  onCommentsEvent(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.onAddComment(event.value as string);
        break;
      case IotAction.EDIT:
        this.onEditComment(event.value as Log);
        break;
      case IotAction.DELETE:
        this.onDeleteComment(event.value as string);
        break;
      default:
        break;
    }
  }

  onOpenComments(device: Device) {
    this.commentsContexts = [
      { name: Concept.DEVICE, checked: true, disabled: false },
      { name: Concept.SITE, checked: false, disabled: !this.masterViewFacade.canReadSite() }
    ];
    this.masterViewFacade.loadComments(device);
  }

  onAddComment(comment: string) {
    this.masterViewFacade.addComment(this.masterViewFacade.selectedItemInGrid()?.id as string, comment);
  }

  onEditComment(comment: Log) {
    this.masterViewFacade.editComment(this.masterViewFacade.selectedItemInGrid()?.id as string, comment);
  }

  onDeleteComment(commentId: string) {
    this.masterViewFacade.deleteComment(this.masterViewFacade.selectedItemInGrid()?.id as string, commentId);
  }

  onSelectDevice(device: Device) {
    if (this.grid && device.id) {
      this.store.dispatch(
        GridsDbActions.selectItemInGridData({
          gridId: this.grid()?.id as string,
          itemId: device.id
        })
      );
    }
  }

  private setIncomingConnectorUrl(device: Device) {
    combineLatest([this.authFacade.refreshToken$, this.authFacade.selectedBusinessProfile$, this.authFacade.userId$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([token, bP, userId]) => {
        if (device?.incomingConnector && token && bP && userId) {
          this.incomingConnectorUrl = `${device.incomingConnector.configuration?.url}device/${userId}/${bP.id}?refreshToken=${token}&deviceIdentifier=${device.identifier}`;
        }
      });
  }
}
