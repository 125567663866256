<div class="header-shell" fxLayout="row">
  @if (displayMenu()) {
    <div class="header-cell-menu">
      @if (displayFilterIcon()) {
        <span (click)="onDisplayMenuClick()" class="ag-header-icon ag-header-cell-filter-button active" data-ref="eFilterButton">
          <mat-icon class="icon">filter_list</mat-icon>
        </span>
      } @else {
        <span (click)="onDisplayMenuClick()" class="ag-header-icon ag-header-cell-menu-button" data-ref="eMenu">
          <mat-icon class="icon">menu</mat-icon>
        </span>
      }
    </div>
  }

  <div class="header-shell__customHeader" fxLayout="row" fxLayoutAlign="start center">
    <div class="customHeaderLabel" fxLayout="row" fxLayoutAlign="start center">
      <ng-content></ng-content>
    </div>
    @if (params()?.enableSorting) {
      <div fxLayout="row">
        <div (click)="onSortRequested('asc', $event)" [ngClass]="ascSort()" class="customSortDownLabel ag-header-icon ag-sort-ascending-icon" data-ref="eSortAsc">
          <mat-icon class="icon">arrow_upward</mat-icon>
        </div>
        <div (click)="onSortRequested('desc', $event)" [ngClass]="descSort()" class="customSortUpLabel ag-header-icon ag-sort-descending-icon" data-ref="eSortDesc">
          <mat-icon class="icon">arrow_downward</mat-icon>
        </div>

        <div
          (click)="onSortRequested('', $event)"
          [ngClass]="{ inactive: true, 'layout-visible': sorted() !== '', 'layout-invisible': sorted() === '' }"
          class="customSortRemoveLabel"
        >
          <mat-icon class="icon">clear</mat-icon>
        </div>
      </div>
    }
  </div>
</div>
