import {
  I4BBasicColumn,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridOptions
} from '@iot-platform/models/grid-engine';

export const VARIABLE_CHART_MASTER_VIEW_NAME = 'variable-chart-mv';

export class VariableChartGrid implements I4BGrid<I4BGridOptions, I4BGridData> {
  masterview = VARIABLE_CHART_MASTER_VIEW_NAME;
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = 'variable-chart';
  userId: string | null = null;
  data: I4BGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: false },
    selectionColumn: { enabled: false }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
    new I4BBasicColumn(
      { displayName: 'DEVICE MODEL' },
      {
        id: 'dateTime',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATE_TIME',
        cell: { type: I4BCellType.DATE },
        isDefault: true,
        autoSize: true,
        sortProperty: 'dateTime'
      },
      {
        order: 1,
        width: 80
      }
    )
  ];
}
